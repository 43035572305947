<template>
  <div>
    <n-page-header title="Segmentações de Contatos" />
    <n-button @click="showCreate = true"> Criar Segmentação </n-button>
    <n-drawer v-model:show="showCreate" :width="702"
      ><n-drawer-content
        title="Criar Segmentação"
        :native-scrollbar="false"
        closable
      >
        <SegmentCreate></SegmentCreate>
      </n-drawer-content>
    </n-drawer>
    <!-- Tabela simples com v-for -->
    <table border="1" width="100%" cellspacing="0" cellpadding="8">
      <thead>
        <tr>
          <th>Tipo</th>
          <th>Nome</th>
          <th></th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(segment, index) in contactSegments" :key="index">
          <td>{{ segment.segment_type }}</td>
          <td>{{ segment.name }}</td>
          <td></td>
          <td>
            <!-- Botão de ações com menu -->
            <n-popover trigger="click" placement="bottom">
              <template #activator>
                <n-button>...</n-button>
              </template>
              <n-list>
                <n-list-item @click="handleEdit(segment)">Editar</n-list-item>
                <n-list-item @click="handleDelete(segment)"
                  >Deletar</n-list-item
                >
                <n-list-item @click="handleExport(segment)"
                  >Exportar</n-list-item
                >
              </n-list>
            </n-popover>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import SegmentCreate from "@/modules/automation/components/SegmentCreate.vue";
import { onMounted } from "vue";

const router = useRouter();
const showCreate = ref(false);
const contactSegments = ref([]);
/**
 * Dados simulados de segmentos de contatos.
 */

// chamar https://flash-growh-db.azurewebsites.net/segments para pegar os segmentos
const requestSegments = async () => {
  try {
    const response = await fetch(
      "https://flash-growh-db.azurewebsites.net/segments",
      {
        headers: {
          Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13",
        },
      }
    );
    const data = await response.json();
    console.log("Dados obtidos com sucesso:", data);
    contactSegments.value = data.data;
  } catch (error) {
    alert(`Erro ao obter os segmentos de contatos: ${error}`);
    console.error("Erro ao obter os segmentos de contatos:", error);
  }
};

/**
 * Ações para cada opção do menu
 */
const handleEdit = (segment) => {
  console.log("Editando o segmento", segment.name);
  router.push({
    name: "SegmentDetails",
    params: { segmentName: segment.name },
  });
};

const handleDelete = (segment) => {
  console.log("Deletando o segmento", segment.name);
};

const handleExport = (segment) => {
  console.log("Exportando o segmento", segment.name);
};
onMounted(() => {
  requestSegments();
});
</script>

<style scoped>
/* Estilos customizados para a tabela */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f0f2f5;
}

td {
  background-color: #ffffff;
}

tbody tr:nth-child(odd) td {
  background-color: #f9f9f9;
}
</style>
