<script setup>
//*Importações do Vue*
import { defineProps, ref, provide } from "vue";


//*Props *
const { content } =
  defineProps([
    "content",
  ]);

const intentText = ref(null);

provide("intentText", { intentText });

</script>
<template>
  <div class="mailcontent-body">
    <shadow-root>
      <div v-html="content.content_html"></div>
    </shadow-root>

    <hr />
    <div v-if="content.attachment">
      <label class="mailcontent-label">2 Attachments</label>

      <div class="attachment-group">
        <div class="attachment-item">
          <div class="attachment-icon text-primary">
            <i class="ri-file-zip-line"></i>
          </div>
          <div class="attachment-body">
            <h6><a href="#">MarketingAssets.zip</a></h6>
            <span>812 KB</span>
          </div>
        </div>
      </div>
      <div class="attachment-item">
        <div class="attachment-icon text-success">
          <i class="ri-file-excel-line"></i>
        </div>
        <div class="attachment-body">
          <h6><a href="#">MarketingReports.xls</a></h6>
          <span>365 KB</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.mailcontent-show .mailbox {
  transform: translateX(-100%);
}

.mailcontent-show .mailcontent {
  left: 0;
}

@media (min-width: 768px) {
  .mailcontent-show .mailcontent {
    left: 230px;
  }
}

@media (min-width: 768px) {
  .mailcontent-show.sidebar-hide .mailcontent {
    left: 0;
  }
}

.no-mailcontent-text {
  text-align: center;
  font-weight: 400;
  color: #a1a8bb;
}

.no-mailcontent-text span {
  font-size: 14px;
  margin-top: 5px;
}

.mailcontent {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100vw;
  height: 100vh;
  background-color: #f5f6fa;
  transition: all 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .mailcontent {
    transition: none;
  }
}

@media (min-width: 768px) {
  .mailcontent {
    width: calc(100vw - 230px);
  }
}

@media (min-width: 1200px) {
  .mailcontent {
    width: auto;
    display: block;
    left: 620px;
    right: 0;
  }
}

.mailcontent-item {
  position: relative;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .mailcontent-item {
    padding: 0 25px;
  }
}

@media (max-width: 767.98px) {
  .mailcontent-item:first-child .mailcontent-header {
    padding-top: 0;
  }
}

.mailcontent-subject {
  padding: 10px;
}

@media (min-width: 768px) {
  .mailcontent-subject {
    padding: 20px;
  }
}

@media (min-width: 1200px) {
  .mailcontent-subject {
    display: flex;
    flex-direction: row-reverse;
    padding: 25px;
  }
}

.mailcontent-subject h4,
.mailcontent-subject .h4 {
  flex: 1;
  color: #212329;
  text-indent: -1px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 1.4;
  padding-left: 5px;
}

@media (min-width: 1200px) {
  .mailcontent-subject h4,
  .mailcontent-subject .h4 {
    margin-top: 0;
    margin-right: 25px;
    padding-left: 0;
  }
}

.mailcontent-subject .nav {
  flex-shrink: 0;
  margin-top: 2.5px;
  margin-left: auto;
}

.mailcontent-subject .nav-link:first-child {
  margin-right: auto;
}

@media (min-width: 1200px) {
  .mailcontent-subject .nav-link:first-child {
    display: none;
  }
}

.mailcontent-subject .nav-link i {
  font-size: 20px;
}

.mailcontent-header {
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.mail-avatar {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.mail-avatar img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.mailcontent-header .mail-avatar {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cccccc;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .mailcontent-header .mail-avatar {
    width: 38px;
    height: 38px;
  }
}

.mailcontent-header .nav {
  padding-left: 5px;
  margin-right: -5px;
}

@media (min-width: 768px) {
  .mailcontent-header .nav {
    padding-left: 10px;
    margin-right: 0;
  }
}

.mailcontent-header .nav-link i {
  font-size: 18px;
}

.mailcontent-subject .nav,
.mailcontent-header .nav {
  position: relative;
}

.mailcontent-subject .nav-link,
.mailcontent-header .nav-link {
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #6b748f;
}

@media (min-width: 768px) {
  .mailcontent-subject .nav-link + .nav-link,
  .mailcontent-header .nav-link + .nav-link {
    margin-left: 2px;
  }
}

.mailcontent-subject .nav-link:hover,
.mailcontent-header .nav-link:hover {
  background-color: rgba(221, 225, 237, 0.6);
}

.mailcontent-subject .nav-link:last-child,
.mailcontent-header .nav-link:last-child {
  width: 16px;
}

.mailcontent-sender {
  flex: 1;
  padding-left: 8px;
}

@media (min-width: 768px) {
  .mailcontent-sender {
    padding-left: 8px;
  }
}

.mailcontent-sender h6,
.mailcontent-sender .h6 {
  font-size: 14px;
  color: #212329;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .mailcontent-sender h6,
  .mailcontent-sender .h6 {
    margin-bottom: 2px;
  }
}

.mailcontent-sender h6 span,
.mailcontent-sender .h6 span {
  color: #a1a8bb;
  font-weight: 400;
  font-size: 13px;
  display: none;
}

@media (min-width: 768px) {
  .mailcontent-sender h6 span,
  .mailcontent-sender .h6 span {
    display: inline-block;
  }
}

.mailcontent-sender p {
  margin-bottom: 0;
  font-size: 12px;
}

@media (min-width: 768px) {
  .mailcontent-sender p {
    font-size: 13px;
  }
}

.mailcontent-time {
  font-size: 11px;
  font-weight: 400;
  color: #a1a8bb;
  display: flex;
  padding-left: 15px;
  justify-content: space-around;
}

@media (min-width: 768px) {
  .mailcontent-time {
    font-size: 12px;
  }
}

.mailcontent-time span {
  display: none;
}

@media (min-width: 768px) {
  .mailcontent-time span {
    display: inline-block;
  }
}

.mailcontent-body {
  background-color: #fff;
  overflow-y: auto;
  padding: 15px 20px 20px;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(221, 225, 237, 0.25),
    0 2px 2px rgba(221, 225, 237, 0.2), 0 4px 4px rgba(221, 225, 237, 0.15),
    0 8px 8px rgba(221, 225, 237, 0.1), 0 16px 16px rgba(221, 225, 237, 0.05);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.7;
  overflow: auto;
  height:fit-content;
}

@media (min-width: 768px) {
  .mailcontent-body {
    margin: 10px 0;
    padding: 20px 25px 25px;
  }
}

.mailcontent-body hr {
  background-color: #a1a8bb;
  margin-top: 25px;
  margin-bottom: 5px;
}

.mailcontent-label {
  display: block;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #a1a8bb;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.attachment-group {
  position: relative;
}

@media (min-width: 768px) {
  .attachment-group {
    display: flex;
  }
}

.attachment-item {
  background-color: rgba(240, 242, 247, 0.6);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .attachment-item {
    min-width: 240px;
  }
}

.attachment-item + .attachment-item {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .attachment-item + .attachment-item {
    margin-top: 0;
    margin-left: 10px;
  }
}

.attachment-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: inherit;
  box-shadow: 0 1px 1px rgba(221, 225, 237, 0.25),
    0 2px 2px rgba(221, 225, 237, 0.2), 0 4px 4px rgba(221, 225, 237, 0.15),
    0 8px 8px rgba(221, 225, 237, 0.1), 0 16px 16px rgba(221, 225, 237, 0.05);
}

@media (min-width: 992px) {
  .attachment-icon {
    width: 50px;
    height: 50px;
  }
}

.attachment-icon i {
  font-size: 28px;
  line-height: 1;
}

.attachment-body {
  flex: 1;
  padding-left: 10px;
}

.attachment-body h6,
.attachment-body .h6 {
  margin-bottom: 2px;
  font-size: 14px;
  color: #212329;
}

.attachment-body a {
  color: inherit;
  text-decoration: none;
}

.attachment-body a:hover,
.attachment-body a:focus {
  color: #dc0f37;
}

.attachment-body span {
  display: block;
  font-size: 12px;
  color: #a1a8bb;
}

.mailcontent-footer {
  padding: 15px;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .mailcontent-footer {
    padding: 25px;
  }
}

.mailcontent-footer .btn {
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
}

.mailcontent-footer .btn + .btn {
  margin-left: 10px;
}

.card-mail {
  border-color: #7695f5;
  background-color: rgba(118, 149, 245, 0.08);
  border-width: 1.5px;
  border-radius: 0;
}

.card-mail .card-body {
  padding: 20px 25px;
  font-size: 14px;
}

.card-mail .card-title {
  color: #212329;
  margin-bottom: 12px;
}

.card-mail ul {
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 15px;
}

.mailcontent-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mailcontent-placeholder i {
  font-size: 128px;
  line-height: 1;
  margin-bottom: 10px;
  color: #a1a8bb;
  opacity: 0.6;
}

.mailcontent-placeholder h5,
.mailcontent-placeholder .h5 {
  font-weight: 400;
  color: #a1a8bb;
  margin-bottom: 0;
}

.wit-ai-logo {
  width: 23px;
  height: 23px;
  margin-bottom: 7px;
}

.gpt-ai-logo {
  width: 22px;
  height: 20px;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
}

.shake {
  animation: shake 0.5s;
  transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mailcontent-wrapper {
  border-top: 1px dashed rgba(145 158 171 / 0.2);
  border-bottom: 1px dashed rgba(145 158 171 / 0.2);
}

.mailcontent-subtitle h4 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-weight: 400;
  flex-shrink: 0;
  padding: 16px;
  font-size: 18px;
}

.reply-wrapper {
  display: flex;
  gap: 5px;
}

.nav-wrapper {
  height: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.left-nav {
  display: flex;
  justify-content: flex-start;
}

.right-nav {
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 170px;
  cursor: pointer;
  height: 40px;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 19px;
  background-color: #dc0f37;
  border: none;
}
.button:active {
  box-shadow: 0 4px #ea6f87;
  transform: translateY(4px);
}
</style>
