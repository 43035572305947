<template>
  <n-card title="Criar Cadencia" bordered>
    <n-form label-width="200px">
      <!-- Nome da Cadencia -->
      <n-form-item label="Qual é o nome desta cadencia?" path="nomeAutomacao">
        <n-input
          v-model:value="form.nomeAutomacao"
          placeholder="Cadencia Sem Título"
        />
      </n-form-item>

      <!-- Critério de Entrada -->
      <n-form-item
        label="Quando os contatos entrarão na cadencia?"
        path="criterioEntrada"
      >
        <n-select
          v-model:value="form.criterioEntrada"
          :options="opcoesEntrada"
          placeholder="Selecione a lista ou segmento"
        />
      </n-form-item>

      <!-- Critério de Saída -->
      <n-form-item
        label="Quando os contatos sairão da cadencia?"
        path="criterioSaida"
      >
        <n-radio-group v-model:value="form.criterioSaida">
          <n-radio value="completo">
            Os contatos receberam todos os e-mails da cadencia.
          </n-radio>
          <n-radio value="naoAtende">
            Os contatos não atendem mais aos critérios de entrada.
          </n-radio>
          <n-radio value="criterioPersonalizado">
            O contato atende aos seguintes critérios
          </n-radio>
        </n-radio-group>
      </n-form-item>

      <!-- E-mail(s) Incluídos na Cadencia -->
      <n-form-item
        label="Quais e-mails estão incluídos na sua cadencia?"
        path="emails"
      >
        <n-empty
          v-if="form.emails.length === 0"
          description="Você ainda não adicionou um e-mail"
        />
        <n-timeline v-else>
          <n-timeline-item v-for="(email, index) in form.emails" :key="index">
            <div class="timeline-step">
              <n-input-group>
                <span>Enviar esse e-mail após:</span>
                <n-input-number
                  :style="{ width: '20%' }"
                  v-model:value="email.intervalo"
                  placeholder=""
                  clearable
                  size="small"
                />
                <n-select
                  :style="{ width: '20%' }"
                  size="small"
                  :options="selectInterval"
                  v-model:value="email.intervaloUnidade"
                  placeholder="dia(s)"
                />
                <span>após o e-mail anterior.</span>
              </n-input-group>
            </div>
            <!-- Conteúdo do E-mail -->
            <n-card class="email-card">
              <div class="conteudo-email">
                <n-input
                  v-model:value="email.assunto"
                  placeholder="Editar Assunto"
                />
                <n-select
                  filterable
                  v-model:value="email.remetente"
                  :options="opcoesRemetente"
                  placeholder="Selecionar Remetente"
                />
              </div>
            </n-card>
          </n-timeline-item>
        </n-timeline>
      </n-form-item>
      <n-button @click="adicionarEmail">Adicionar E-mail</n-button
      ><br /><br />
    </n-form>
    <n-button type="primary" @click="salvarAutomacao">Salvar</n-button>
    <n-button @click="mostrarOpcoesAutomacao">Opções de Cadencia</n-button>
  </n-card>
</template>

<script setup>
import { ref } from "vue";

const form = ref({
  nomeAutomacao: "",
  criterioEntrada: null,
  criterioSaida: "completo",
  categorias: null,
  emails: [],
});

const opcoesEntrada = ref([
  {
    label: "A primeira vez que um contato for adicionado a",
    value: "primeiraVez",
  },
  // Adicione outras opções aqui
]);

const selectInterval = ref([
  { label: "Horas", value: "horas" },
  { label: "Dia", value: "dia" },
  { label: "Semana", value: "semana" },
  { label: "Mês", value: "mes" },
]);

const opcoesRemetente = ref([
  { label: "Remetente 1", value: "remetente1" },
  { label: "Remetente 2", value: "remetente2" },
  // Adicione outras opções de remetente aqui
]);

function adicionarEmail() {
  form.value.emails.push({
    assunto: "",
    remetente: "",
    intervalo: 0,
    intervaloUnidade: "dia",
  });
}

function salvarAutomacao() {
  // Função para salvar a cadencia
}

function mostrarOpcoesAutomacao() {
  // Função para exibir opções adicionais de cadencia
}
</script>

<style scoped>
.email-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-step {
  margin-bottom: 8px;
}

.email-card {
  width: 100%;
  margin-bottom: 8px;
}

.conteudo-email {
  display: flex;
  justify-content: space-between;
}
</style>
