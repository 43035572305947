<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { NButton, NDropdown } from "naive-ui";
import router from "@/router";
import { toast } from "vue3-toastify";
import { useAuthStore } from "../../modules/auth/hooks/useAuth";

const authStore = useAuthStore();
const { t, locale } = useI18n();

const activeTab = ref("login");
const loginEmail = ref("");
const loginPassword = ref("");
const signupEmail = ref("");
const signupPassword = ref("");
const resetEmail = ref("");

const languages = {
  en: "English",
  pt: "Português",
};

function changeLanguage(lang) {
  locale.value = lang;
}

const currentLanguageLabel = computed(() => languages[locale.value]);

const languageOptions = [
  { label: "English", key: "en" },
  { label: "Português", key: "pt" },
];

async function handleLogin() {
  try {
    const { message, success } = await authStore.login(
      loginEmail.value,
      loginPassword.value
    );

    if (success) {
      toast(t(`auth.login_success`), {
        position: "top-right",
        autoClose: 5000,
        type: "success",
        toastStyle: {
          "--toastify-icon-color-success": "#0f5132",
          "--toastify-color-success": "#0f5132",
        },
        progressStyle: {
          "--toastify-progress-bar-color-success": "#0f5132",
        },
      });

      return router.push("/");
    }

    toast(t(`auth.login_error`, { message: message }), {
      position: "top-right",
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
      },
      progressStyle: {
        "--toastify-progress-bar-color-error": "#c60d31",
      },
    });
  } catch (error) {
    toast(t("auth.login_error", { message: error.message }), {
      position: "top-right",
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
      },
      progressStyle: {
        "--toastify-progress-bar-color-error": "#c60d31",
      },
    });
  }
}

async function handleSignup() {
  try {
    const { message, success } = await authStore.register(
      signupEmail.value,
      signupPassword.value
    );

    if (success) {
      toast(t("auth.signup_success"), {
        position: "top-right",
        autoClose: 5000,
        type: "success",
        toastStyle: {
          "--toastify-icon-color-success": "#0f5132",
          "--toastify-color-success": "#0f5132",
        },
        progressStyle: {
          "--toastify-progress-bar-color-success": "#0f5132",
        },
      });
      return router.push("/");
    }

    toast(t("auth.signup_error", { message }), {
      position: "top-right",
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
      },
      progressStyle: {
        "--toastify-progress-bar-color-error": "#c60d31",
      },
    });
  } catch (err) {
    toast(t("auth.signup_error", { message: err.message }), {
      position: "top-right",
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
      },
      progressStyle: {
        "--toastify-progress-bar-color-error": "#c60d31",
      },
    });
  }
}

async function handleResetPassword() {
  try {
    if (!resetEmail.value) {
      toast(
        t("auth.reset_error", { message: "Entre com um e-mail válido..." }),
        {
          position: "top-right",
          autoClose: 5000,
          type: "error",
          toastStyle: {
            "--toastify-icon-color-error": "#c60d31",
            "--toastify-color-error": "#c60d31",
          },
          progressStyle: {
            "--toastify-progress-bar-color-error": "#c60d31",
          },
        }
      );
      return;
    }

    const { message, success } = await authStore.reset(resetEmail.value);

    if (success) {
      toast(t("auth.reset_success"), {
        position: "top-right",
        autoClose: 5000,
        type: "success",
        toastStyle: {
          "--toastify-icon-color-success": "#0f5132",
          "--toastify-color-success": "#0f5132",
        },
        progressStyle: {
          "--toastify-progress-bar-color-success": "#0f5132",
        },
      });
      activeTab.value = "login";

      return;
    }
    toast(t("auth.reset_error", { message: message }), {
      position: "top-right",
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
      },
      progressStyle: {
        "--toastify-progress-bar-color-error": "#c60d31",
      },
    });
  } catch (err) {
    toast(t("auth.reset_error", { message: err.message }), {
      position: "top-right",
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
      },
      progressStyle: {
        "--toastify-progress-bar-color-error": "#c60d31",
      },
    });
  }
}
</script>
<template>
  <div class="auth-page">
    <div class="language-switcher">
      <n-dropdown
        :options="languageOptions"
        trigger="click"
        @select="changeLanguage"
      >
        <n-button>{{ currentLanguageLabel }}</n-button>
      </n-dropdown>
    </div>

    <n-card :title="$t('auth.title')" class="auth-card">
      <n-tabs v-model:value="activeTab">
        <n-tab-pane :name="'login'" :tab="$t('auth.login')">
          <n-form @submit.prevent="handleLogin" label-width="120">
            <n-form-item :label="$t('auth.email')">
              <n-input
                v-model:value="loginEmail"
                type="email"
                :placeholder="$t('auth.email_placeholder')"
              />
            </n-form-item>
            <n-form-item :label="$t('auth.password')">
              <n-input
                v-model:value="loginPassword"
                type="password"
                :placeholder="$t('auth.password_placeholder')"
              />
            </n-form-item>
            <n-form-item>
              <n-button type="primary" size="large" block @click="handleLogin">
                {{ $t("auth.login") }}
              </n-button>
            </n-form-item>
            <n-button text @click="activeTab = 'reset'">
              {{ $t("auth.forgot_password") }}
            </n-button>
          </n-form>
        </n-tab-pane>

        <n-tab-pane :name="'signup'" :tab="$t('auth.signup')">
          <n-form @submit.prevent="handleSignup" label-width="120">
            <n-form-item :label="$t('auth.email')">
              <n-input
                v-model:value="signupEmail"
                type="email"
                :placeholder="$t('auth.email_placeholder')"
              />
            </n-form-item>
            <n-form-item :label="$t('auth.password')">
              <n-input
                v-model:value="signupPassword"
                type="password"
                :placeholder="$t('auth.password_placeholder')"
              />
            </n-form-item>
            <n-form-item>
              <n-button type="primary" size="large" block @click="handleSignup">
                {{ $t("auth.signup") }}
              </n-button>
            </n-form-item>
          </n-form>
        </n-tab-pane>

        <n-tab-pane :name="'reset'" :tab="$t('auth.reset_password')">
          <n-form @submit.prevent="handleResetPassword" label-width="120">
            <n-form-item :label="$t('auth.email')">
              <n-input
                v-model:value="resetEmail"
                type="email"
                :placeholder="$t('auth.email_placeholder')"
              />
            </n-form-item>
            <n-form-item>
              <n-button
                type="primary"
                size="large"
                block
                @click="handleResetPassword"
              >
                {{ $t("auth.reset_password") }}
              </n-button>
            </n-form-item>
          </n-form>
        </n-tab-pane>
      </n-tabs>
    </n-card>
  </div>
</template>
<style scoped>
.auth-page {
  position: relative;
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
}

.language-switcher {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.auth-card {
  padding: 10px;
}
</style>
