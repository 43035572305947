<template>
  <n-page-header :title="segmentName" />

  <div class="actions">
    <n-button @click="exportCsv">Export CSV</n-button>
    <n-dropdown :options="segmentOptions">
      <n-button>Segment Options</n-button>
    </n-dropdown>
  </div>

  <n-table
    v-if="segmentContacts.length"
    border="1"
    width="100%"
    cellspacing="0"
    cellpadding="8"
  >
    <thead>
      <tr>
        <th>E-mail</th>
        <th>Adicionado em</th>
        <th>Último e-mail</th>
        <th>Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(contact, index) in segmentContacts" :key="index">
        <td>{{ contact.email }}</td>
        <td>{{ contact.dateAdded }}</td>
        <td>{{ contact.dateUpdated }}</td>
        <td>
          <!-- Botão de ações com menu -->
          <n-popover trigger="click" placement="bottom">
            <template #activator>
              <n-button>...</n-button>
            </template>
            <n-list>
              <n-list-item @click="handleEdit(contact)">Editar</n-list-item>
              <n-list-item @click="handleDelete(contact)">Deletar</n-list-item>
            </n-list>
          </n-popover>
        </td>
      </tr>
    </tbody>
  </n-table>
  <div v-else>
    <p>Nenhum dado encontrado.</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

// Captura o parâmetro da rota
const route = useRoute();
const segmentName = route.params.segmentName || "Segmento Desconhecido";

/**
 * Dados simulados dos contatos do segmento.
 */
const segmentContacts = ref([]);

/**
 * Simulando a busca de dados ao montar o componente
 */
onMounted(() => {
  // Exemplo de dados específicos para cada segmentação
  if (segmentName === "Testers Flashvolve") {
    segmentContacts.value = [
      {
        email: "mmarculo@flashvolve.com",
        dateAdded: "08/21/2024",
        dateUpdated: "08/22/2024",
      },
      {
        email: "lalvarenga@flashvolve.com",
        dateAdded: "08/21/2024",
        dateUpdated: "08/22/2024",
      },
      {
        email: "yfernandes@flashvolve.com",
        dateAdded: "08/21/2024",
        dateUpdated: "08/22/2024",
      },
    ];
  } else if (segmentName === "Profissas") {
    segmentContacts.value = [
      {
        email: "profissional1@flashvolve.com",
        dateAdded: "08/10/2024",
        dateUpdated: "08/11/2024",
      },
      {
        email: "profissional2@flashvolve.com",
        dateAdded: "08/10/2024",
        dateUpdated: "08/11/2024",
      },
    ];
  }

  console.log("Dados carregados:", segmentContacts.value);
});

/**
 * Ações simuladas
 */
const exportCsv = () => {
  console.log("Exportando CSV dos contatos do segmento");
};

const handleEdit = (contact) => {
  console.log("Editando contato", contact.email);
};

const handleDelete = (contact) => {
  console.log("Deletando contato", contact.email);
};

const segmentOptions = [
  {
    label: "Editar",
    key: "edit",
    onClick: () => console.log("Editando segmento"),
  },
  {
    label: "Deletar",
    key: "delete",
    onClick: () => console.log("Deletando segmento"),
  },
];
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
</style>
