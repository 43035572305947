import axios from "axios";

export class FetchApiEmails {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.headers = {};
    this.defaultOptions = { headers: this.headers };
  }

  async sendRequest(endpoint, method = "GET", data = null) {
    const requestOptions = {
      ...this.defaultOptions,
      method,
      url: this.baseUrl + endpoint,
    };
    if (data) requestOptions.data = data;

    try {
      const response = await axios(requestOptions);
      return response.data;
    } catch (error) {
      console.error(`Error sending ${method} request to ${endpoint}:`, error);
      throw error;
    }
  }

  async archiveMessage(id, path, uids) {
    const endpoint = `/email-reader/archive/${id}?path=${path}`;

    const data = {
      uids: uids,
    };

    return await this.sendRequest(endpoint, "POST", data);
  }

  async updateMessageContent(messageContentID, data) {
    const endpoint = `/inbox-emails/${messageContentID}`;

    return await this.sendRequest(endpoint, "PUT", data);
  }

  async readMessage(id, uid, boxPath) {
    const endpoint = `/email-reader/flags/${id}?flag=seen&uid=${uid}&path=${boxPath}`;

    return await this.sendRequest(endpoint, "POST", null);
  }

  async sendMessage(messageForm) {
    this.headers = { "Content-Type": "multipart/form-data" };

    const endpoint = `/email-sender/send`;
    await this.sendRequest(endpoint, "POST", messageForm);

    this.headers = {};

    return;
  }

  async replyMessage(messageForm) {
    this.headers = { "Content-Type": "multipart/form-data" };

    const endpoint = `/email-sender/reply`;
    await this.sendRequest(endpoint, "POST", messageForm);

    this.headers = {};

    return;
  }
}
