import { createI18n } from "vue-i18n";

const messages = {
  en: {
    menu: {
      tasks: "Tasks",
      actors: "Actors",
      integrations: "Integrations",
      files: "Files",
      templates: "Templates",
      settings: "Settings",
    },
    pages: {
      tasks: "Tasks Page Content 1",
      actors: "Actors Page Content",
      integrations: "Integrations Page Content",
      templates: "Templates Page Content",
      settings: "Settings Page Content",
      files: "Files Page Content",
    },
    auth: {
      title: "Authentication",
      login: "Login",
      signup: "Sign Up",
      reset_password: "Reset Password",
      email: "Email",
      password: "Password",
      email_placeholder: "Enter your email",
      password_placeholder: "Enter your password",
      forgot_password: "Forgot your password?",
      login_success: "Login successful!",
      signup_success: "Sign-up successful! Check your email for confirmation.",
      reset_success: "Password reset email sent! Check your inbox.",
      login_error: "Error during login: {message}",
      signup_error: "Error during sign-up: {message}",
      reset_error: "Error during password reset: {message}",
    },
    notfound: {
      title: "Page Not Found",
      description: "Sorry, the page you are looking for does not exist.",
      go_home: "Go Back Home",
    },
  },
  pt: {
    menu: {
      tasks: "Tarefas",
      actors: "Atores",
      integrations: "Integrações",
      files: "Arquivos",
      templates: "Templates",
      settings: "Configurações",
    },
    pages: {
      tasks: "Conteúdo da Página de Tarefas",
      actors: "Conteúdo da Página de Atores",
      integrations: "Conteúdo da Página de Integrações",
      templates: "Conteúdo da Página de Templates",
      settings: "Conteúdo da Página de Configurações",
      files: "Conteúdo da Página de Arquivos",
    },
    auth: {
      title: "Autenticação",
      login: "Entrar",
      signup: "Cadastrar",
      reset_password: "Redefinir Senha",
      email: "Email",
      password: "Senha",
      email_placeholder: "Digite seu email",
      password_placeholder: "Digite sua senha",
      forgot_password: "Esqueceu sua senha?",
      login_success: "Login realizado com sucesso!",
      signup_success:
        "Cadastro realizado com sucesso! Verifique seu email para confirmar.",
      reset_success:
        "Email de redefinição de senha enviado! Verifique sua caixa de entrada.",
      login_error: "Erro ao fazer login: {message}",
      signup_error: "Erro ao se cadastrar: {message}",
      reset_error: "Erro ao redefinir a senha: {message}",
    },
    notfound: {
      title: "Página Não Encontrada",
      description: "Desculpe, a página que você está procurando não existe.",
      go_home: "Voltar para a Página Inicial",
    },
  },
  es: {
    menu: {
      tasks: "Tareas",
      actors: "Actores",
      integrations: "Integraciones",
      files: "Archivos",
      templates: "Plantillas",
      settings: "Configuración",
    },
    pages: {
      tasks: "Contenido de la página de tareas 1",
      actors: "Contenido de la página de actores",
      integrations: "Contenido de la página de integraciones",
      templates: "Contenido de la página de plantillas",
      settings: "Contenido de la página de configuración",
      files: "Contenido de la página de archivos",
    },
    auth: {
      title: "Autenticación",
      login: "Iniciar sesión",
      signup: "Registrarse",
      reset_password: "Restablecer contraseña",
      email: "Correo electrónico",
      password: "Contraseña",
      email_placeholder: "Ingrese su correo electrónico",
      password_placeholder: "Ingrese su contraseña",
      forgot_password: "¿Olvidó su contraseña?",
      login_success: "¡Inicio de sesión exitoso!",
      signup_success:
        "¡Registro exitoso! Verifique su correo electrónico para confirmación.",
      reset_success:
        "¡Correo de restablecimiento de contraseña enviado! Revise su bandeja de entrada.",
      login_error: "Error durante el inicio de sesión: {message}",
      signup_error: "Error durante el registro: {message}",
      reset_error: "Error durante el restablecimiento de contraseña: {message}",
    },
    notfound: {
      title: "Página no encontrada",
      description: "Lo sentimos, la página que busca no existe.",
      go_home: "Volver al inicio",
    },
  },
};

const i18n = createI18n({
  legacy: false,
  locale: navigator.language.split("-")[0],
  fallbackLocale: "pt",
  messages,
});

export default i18n;
