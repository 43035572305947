<template>
  <div>
    <n-page-header title="Cadencias" />
    <n-button @click="showCreate = true"> Criar Cadencias </n-button>
    <n-drawer v-model:show="showCreate" :width="702"
      ><n-drawer-content :native-scrollbar="false" closable>
        <CadenceCreate></CadenceCreate>
      </n-drawer-content>
    </n-drawer>
    <!-- Tabela simples com v-for -->
    <table border="1" width="100%" cellspacing="0" cellpadding="8">
      <thead>
        <tr>
          <th>Tipo</th>
          <th>Nome</th>
          <th>Quantidade</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(segment, index) in contactSegments" :key="index">
          <td>{{ segment.type }}</td>
          <td>{{ segment.name }}</td>
          <td>{{ segment.count }}</td>
          <td>
            <!-- Botão de ações com menu -->
            <n-popover trigger="click" placement="bottom">
              <template #activator>
                <n-button>...</n-button>
              </template>
              <n-list>
                <n-list-item @click="handleEdit(segment)">Editar</n-list-item>
                <n-list-item @click="handleDelete(segment)"
                  >Deletar</n-list-item
                >
                <n-list-item @click="handleExport(segment)"
                  >Exportar</n-list-item
                >
              </n-list>
            </n-popover>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import CadenceCreate from "@/modules/automation/views/cadence/components/CadenceCreate.vue";

const router = useRouter();
const showCreate = ref(false);
/**
 * Dados simulados de segmentos de contatos.
 */
const contactSegments = ref([
  {
    type: "Segmento",
    name: "Profissas",
    count: 0,
  },
  {
    type: "Lista",
    name: "Testers Flashvolve",
    count: 3,
  },
]);

/**
 * Ações para cada opção do menu
 */
const handleEdit = (segment) => {
  console.log("Editando o segmento", segment.name);
  router.push({
    name: "SegmentDetails",
    params: { segmentName: segment.name },
  });
};

const handleDelete = (segment) => {
  console.log("Deletando o segmento", segment.name);
};

const handleExport = (segment) => {
  console.log("Exportando o segmento", segment.name);
};
</script>

<style scoped>
/* Estilos customizados para a tabela */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f0f2f5;
}

td {
  background-color: #ffffff;
}

tbody tr:nth-child(odd) td {
  background-color: #f9f9f9;
}
</style>
