import { defineStore } from "pinia";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../providers/firebase";

export const useAuthStore = defineStore("store", () => {
  const fetchCurrentUser = async () => {
    try {
      return new Promise((resolve, reject) => {
        try {
          auth.onAuthStateChanged(async (user) => {
            if (!user)
              resolve({
                message: "Usuário não encontrado...",
                data: null,
                success: false,
              });
            resolve({
              message: "Usuário encontrado!",
              data: user,
              success: true,
            });
          });
        } catch (error) {
          console.error("Error fetching current user: ", error);
          reject({
            message: error.message || error,
            data: null,
            success: false,
          });
        }
      });
    } catch (error) {
      console.error("Error fetching current user: ", error);
      return { message: error, data: null, success: false };
    }
  };

  const login = async (email, password) => {
    const response = await signInWithEmailAndPassword(auth, email, password)
      .then(async (response) => {
        const { user } = response;
        if (!user)
          return { message: "Usuário não encontrado...", success: false };
        return { message: "Login efetuado!", success: true };
      })
      .catch((error) => {
        console.error("Error signing in: ", error);
        return { message: error, success: false };
      });

    return response;
  };

  const register = async (email, password) => {
    const response = await createUserWithEmailAndPassword(auth, email, password)
      .then(async (response) => {
        const { user } = response;
        if (!user)
          return {
            message: "Usuário não encontrado...",
            data: null,
            success: false,
          };
        return { message: "Usuário criado!", data: user, success: true };
      })
      .catch((error) => {
        console.error("Error creating new user: ", error);
        return { message: error, data: null, success: false };
      });

    return response;
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
    }
  };

  const reset = async (email) => {
    try {
      sendPasswordResetEmail(auth, email);
      return { message: "Email enviado!", success: true };
    } catch (error) {
      console.error("Error sending password reset email: ", error);
      return { message: error, success: false };
    }
  };

  return {
    fetchCurrentUser,
    login,
    register,
    logout,
    reset,
  };
});
