import { isToday } from "@/modules/inbox/utils/is-today";
import { months } from "@/modules/inbox/utils/months";

//* Conteúdo *//
export const truncateContent = (content) => {
  if (content) {
    return content.length > 100 ? content.substring(0, 40) + "..." : content;
  }

  return "";
};

export const switchTermsToPTBR = (input) => {
  const terms = {
    Mailing_Exclusion: "Exclusão de e-mail",
    Presentation: "Apresentação",
    Prospecting: "Prospecção",
    Referral: "Indicação",
    Scheduling: "Agendamento",
    auto_reply: "Resposta automática",
    no_interest: "Sem interesse",
  };

  return terms[input] || input;
};

//* Data *//
export const formatTime = (dateString = new Date()) => {
  const date = new Date(dateString);
  const now = new Date();

  const thisYearDate = `${date.getDate()} de ${months[date.getMonth()]}`;
  const pastYearsDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  const isCurrentDay = isToday(date);

  const hours = (date.getUTCHours() - 3 + 24) % 24;
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes}`;

  if (date.getFullYear() !== now.getFullYear()) {
    return pastYearsDate;
  } else if (isCurrentDay) {
    return formattedTime;
  } else {
    return thisYearDate;
  }
};

export const getFirstChar = (value) => {
  return value.charAt(0) || "F";
};

// Funções para codificar/decodificar em Base64url
export const base64UrlEncode = (str) => {
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
};

export const base64UrlDecode = (str) => {
  let base64 = str.replace(/-/g, "+").replace(/_/g, "/");
  while (base64.length % 4) {
    base64 += "=";
  }
  return atob(base64);
};
