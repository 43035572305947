<script setup>
//*Vue relacionados*
import { reactive, inject, watch, ref } from "vue";
import { useRoute } from "vue-router";
const { inbox } = inject("inbox");

//*Componentes*
import Loader from "../../../common/components/Loader.vue";

//*Bibliotecas
import axios from "axios";
import { toast } from "vue3-toastify";
import config from "../../../../config/environment";

//*Instâncias e variáveis*
const route = useRoute();

//*Props e emits*
const props = defineProps({
  styleHeight: {
    type: String,
    default: "70%",
  },
  styleWidth: {
    type: String,
    default: "60%",
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close", "save"]);

//*Controles de estados*
const isLoading = ref(false);
const form = reactive({
  firstname: null,
  lastname: null,
  email: null,
  phone: null,
});

//*Funções auxiliares*
const validateEmail = (email) => {
  if (!email) return null;
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

//*Função para criar oportunidade*
const create = async () => {
  if (!form.firstname || !form.lastname || !validateEmail(form.email)) {
    toast("Oops! Parece que algum campo não foi selecionado!", {
      position: "top-right",
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "#c60d31",
      },
    });

    return;
  }

  const businessClientId = inbox.value.business_client_id;

  isLoading.value = true;

  await axios.post(`${config.API_EMAILS}/oportunity`, {
    firstname: form.firstname,
    lastname: form.lastname,
    email: form.email,
    phone: form.phone,
    comments: null,
    score: null,
    call: null,
    qualified: null,
    business_client_id: businessClientId,
  });

  form.firstname = null;
  form.lastname = null;
  form.email = null;
  form.phone = null;

  isLoading.value = false;
};

const vMask = {
  mounted(el) {
    $(el).ready(function () {
      $(el).mask("(00) 00000-0000");
    });
  },
};

//*Watchers*
watch(
  () => route.fullPath,
  () => {
    form.firstname = null;
    form.lastname = null;
    form.email = null;
    form.phone = null;
  }
);
</script>

<template>
  <teleport to="body">
    <div class="modal" v-if="isOpen">
      <div
        class="modal-content"
        :style="{ width: styleWidth, height: styleHeight }"
      >
        <div class="modal-bar">
          <h5 class="modal-text">Nova oportunidade</h5>
          <img
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="modal-image"
            @click="emit('input')"
          />
        </div>
        <div class="container-content">
          <Loader
            v-if="isLoading"
            :style-height="'60px'"
            :style-width="'60px'"
            class="loader"
          />
          <div v-else class="form-opportunity">
            <form>
              <div class="group">
                <input v-model="form.firstname" type="text" required />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Nome</label>
              </div>

              <div class="group">
                <input v-model="form.lastname" type="text" required />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Sobrenome</label>
              </div>

              <div class="group">
                <input v-model="form.email" type="text" required />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Email</label>
              </div>

              <div class="group">
                <input v-model="form.phone" type="tel" required v-mask />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Contato</label>
              </div>
            </form>
            <button class="button-opportunity" @click="create">Criar</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
h5 {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-text {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 auto;
}

.modal-content {
  margin-bottom: 133px;
  margin-left: 265px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.modal-bar {
  width: 100%;
  height: 60px;
  padding: 12px 8px 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  background-color: #dc0f37;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.container-content {
  display: flex;
  flex-direction: column;
  width: 120%;
  height: 83%;
  align-items: center;
  justify-content: center;
}

.loader {
  margin-right: 300px;
}

.modal-text {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.button-opportunity {
  cursor: pointer;
  width: 200px;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
  margin-left: 39px;
  border-radius: 10px;
  background-color: #dc0f37;
  border: none;
}
.button-opportunity:active {
  box-shadow: 0 4px #ea6f87;
  transform: translateY(4px);
}

/* CSS dos inputs */
* {
  box-sizing: border-box;
}

/* basic stylings ------------------------------------------ */
body {
  background: url(https://scotch.io/wp-content/uploads/2014/07/61.jpg);
}
.container {
  font-family: "Roboto";
  width: 600px;
  margin: 30px auto 0;
  display: block;
  background: #fff;
  padding: 10px 50px 50px;
}
h2 {
  text-align: center;
  margin-bottom: 50px;
}
h2 small {
  font-weight: normal;
  color: #888;
  display: block;
}
.footer {
  text-align: center;
}
.footer a {
  color: #53b2c8;
}

/* form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 45px;
}
input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #757575;
}
input:focus {
  outline: none;
}

/* LABEL ======================================= */
label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #dc0f37;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 300px;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #dc0f37;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #dc0f37;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #dc0f37;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #dc0f37;
  }
  to {
    width: 0;
    background: transparent;
  }
}
</style>
