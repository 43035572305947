<script setup>
import Loader from "../../../common/components/Loader.vue";
import { ref, watch, inject } from "vue";

const { intentText } = inject("inbox");

const props = defineProps({
  styleHeight: {
    type: String,
    default: "70%",
  },
  styleWidth: {
    type: String,
    default: "60%",
  },
  intents: {
    type: Array,
    default: null,
  },
});

const value = ref(null);

const options = [
  {
    label: "Exclusão de e-mail",
    value: "Mailing_Exclusion",
    disabled: false,
  },
  {
    label: "Apresentação",
    value: "Presentation",
    disabled: false,
  },
  {
    label: "Prospecção",
    value: "Prospecting",
    disabled: false,
  },
  {
    label: "Indicação",
    value: "Referral",
    disabled: false,
  },
  {
    label: "Agendamento",
    value: "Scheduling",
    disabled: false,
  },
  {
    label: "Resposta automática",
    value: "auto_reply",
    disabled: false,
  },
  {
    label: "Sem interesse",
    value: "no_interest",
    disabled: false,
  },
];

const emit = defineEmits(["close", "save"]);

watch(
  () => props.intents,
  (newIntents) => {
    if (newIntents && newIntents.length > 0) {
      value.value = newIntents[0].name;
    }
  }
);

watch(value, (newValue) => {
  if (newValue) {
    intentText.value = newValue;

    console.log("o valor é: ", intentText.value);
  }
});
</script>

<template>
  <teleport to="body">
    <div class="modal">
      <div
        class="modal-content"
        :style="{ width: styleWidth, height: styleHeight }"
      >
        <div class="modal-bar">
          <div class="modal-bar">
            <h5 class="modal-text">Intenção de e-mail</h5>
            <img
              alt="image"
              src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
              class="modal-image"
              @click="emit('close')"
            />
          </div>
        </div>
        <div class="container-content">
          <span class="text-intent" v-if="intents !== null"
            >{{
              intents === "Não-classificado"
                ? "Sem intenção. Selecione uma:"
                : "Deseja salvar intenção atual?"
            }}
          </span>
          <div v-if="intents !== null" class="select-intention">
            <n-space vertical>
              <n-select v-model:value="value" :options="options" />
            </n-space>
          </div>

          <Loader
            v-if="intents === null"
            :style-height="'60px'"
            :style-width="'60px'"
            class="loader"
          />

          <span v-if="intents === null" class="loading"
            >Analisando intenção</span
          >

          <div v-if="intents !== null" class="button-wrapper">
            <div class="button-one" v-if="intents === 'Não-classificado'">
              <button class="button" @click="emit('save')">Salvar</button>
            </div>

            <div v-else class="button-two">
              <button class="button" @click="emit('save')">Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style>
h5 {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-text {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 auto;
}

.modal-content {
  margin-bottom: 190px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.modal-bar {
  width: 100%;
  height: 60px;
  padding: 12px 8px 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  background-color: #dc0f37;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.container-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  align-items: center;
  justify-content: center;
}

.loader {
  margin-right: 300px;
}

.container-content textarea {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  resize: none;
}

.modal-text {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.button {
  display: flex;
  cursor: pointer;
  height: 40px;
  color: #fff;
  padding: 10px;
  gap: 5px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #dc0f37;
  border: none;
  align-items: center;
  justify-content: center;
}
.button:active {
  box-shadow: 0 4px #ea6f87;
  transform: translateY(4px);
}

.button-cancel:active {
  box-shadow: 0 4px rgb(111, 104, 104);
  transform: translateY(4px);
}

.button-icon {
  width: 1.2em;
  height: 1.3em;
}

.button-two {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.container-content textarea {
  width: 1100px;
  height: 400px;
  outline: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  resize: none;
}

.loading {
  font-size: 16px;
}

.button-cancel {
  background-color: #f0f0f0;
  color: #000;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.tag {
  background-color: rgba(220, 15, 56, 0.501);
  color: white;
  padding: 12px;
  margin: 2px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}
.tag span {
  padding: 0px 5px;
  font-size: 12px;
  cursor: pointer;
}

.select-intention {
  width: 55%;
}

.text-intent {
  margin-bottom: 25px;
}
</style>
