import axios from "axios";
import config from "../../../../config/environment.js";

export class LanguageModelGateway {
  async detectEntitiesAndIntents(text) {
    try {
      const data = {
        content_text: text,
      };

      const response = await axios.post(
        `${config.API_EMAILS}/language-model/`,
        data,
        {
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  }

  async generateResponse(prompt, role) {
    try {
      const data = {
        prompt: prompt,
        role: role,
      };

      const response = await axios.post(
        `${config.API_EMAILS}/language-model/generate-response`,
        data,
        {
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  }
}
