<template>
    <n-menu :options="menuOptions" @update:value="handleMenuClick" :collapsed="false" />
  </template>
  
  <script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  
  const router = useRouter()
  
  // Definição das opções do menu para a área de automação
  const menuOptions = ref([
    {
      label: 'Dashboard de Automação',
      key: '/automation/dashboard',
      // icon: () => <n-icon><dashboard-outlined /></n-icon>,
    },
    {
      label: 'Contatos',
      key: '/automation/contacts',
      // icon: () => <n-icon><contacts-outlined /></n-icon>,
    },,
    {
      label: 'Cadencias',
      key: '/automation/cadences',
      // icon: () => <n-icon><contacts-outlined /></n-icon>,
    },
  ])
  
  /**
   * Função de navegação ao clicar em um item do menu
   * @param {string} key - Caminho da rota selecionada
   */
  const handleMenuClick = (key) => {
    router.push(key)
  }
  </script>
  
  <style scoped>
  /* Estilos customizados */
  </style>
  