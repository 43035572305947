<script setup>
//*Importações do Vue*
import { defineProps, defineEmits, watch, ref, inject } from "vue";

//*Loader*
import Loader from "../../../common/components/Loader.vue";

//*Componentes
import HeaderBox from "../../inbox/components/HeaderBox.vue";
import MessageList from "../../inbox/components/MessageList.vue";

const {
  currentTotalMessages,
  selectedStoredMails,
  currentPageEmailIds,
  inboxMessage,
} = inject("inbox");

const { mails, ready, calculateIndexes, nextPage, previousPage } = defineProps([
  "mails",
  "ready",
  "currentPage",
  "calculateIndexes",
  "startIndex",
  "endIndex",
  "nextPage",
  "previousPage",
]);

const emit = defineEmits();

const selectedAll = ref(false);

watch(
  () => currentPageEmailIds.value,
  () => {
    if (!currentPageEmailIds.value.length) {
      selectedAll.value = false;
      return;
    }

    const allSelected = currentPageEmailIds.value.some((id) =>
      selectedStoredMails.value.includes(id)
    );

    selectedAll.value = allSelected;
  },
  { immediate: true }
);

watch(
  () => selectedStoredMails.value.length,
  () => {
    const storedCondition = currentPageEmailIds.value.some((id) =>
      selectedStoredMails.value.includes(id)
    );

    if (selectedStoredMails.value.length !== 0 && storedCondition) {
      selectedAll.value = true;
    }

    if (storedCondition === false) {
      selectedAll.value = false;
    }
  },
  { immediate: true }
);
</script>
<template>
  <HeaderBox
    :calculate-indexes="calculateIndexes"
    :mails="mails"
    :start-index="startIndex"
    :end-index="endIndex"
    :next-page="nextPage"
    :previous-page="previousPage"
  />
  <Loader
    v-if="!ready"
    class="loader-animation"
    :style-height="'50px'"
    :style-width="'50px'"
  />
  <MessageList
    :mails="mails"
    :ready="ready"
    :current-total-messages="currentTotalMessages"
    :inbox-message="inboxMessage"
  />
</template>
<style scoped>
.loader-animation {
  position: absolute;
  top: 50%;
  left: 23%;
  transform: translate(-50%, -50%);
}
</style>
