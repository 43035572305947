import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDtE6AjeQxXXelVvVjkeAushX7c9f75MkQ",
  authDomain: "sql-flasvolve.firebaseapp.com",
  projectId: "sql-flasvolve",
  storageBucket: "sql-flasvolve.appspot.com",
  messagingSenderId: "575456384632",
  appId: "1:575456384632:web:5b3813bc932a8099007f8d",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { auth };
