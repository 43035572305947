<script setup>
//*Importações do Vue
import { defineProps, defineEmits, watch, ref, inject } from "vue";

//*Componentes *
import MailSidebar from "../components/MailSidebar.vue";
import MailContent from "../components/MailContent.vue";
import MenuCompose from "../components/MenuCompose.vue";
import EmptyMailContent from "../components/EmptyMailContent.vue";

const {
  currentTotalMessages,
  selectedStoredMails,
  currentPageEmailIds,
  inboxMessage,
} = inject("inbox");

const {
  mails,
  ready,
  calculateIndexes,
  nextPage,
  previousPage,
  isLoading,
  generateResponse,
  detectEntitiesAndIntents,
  content,
} = defineProps([
  "mails",
  "ready",
  "currentPage",
  "calculateIndexes",
  "startIndex",
  "endIndex",
  "nextPage",
  "previousPage",
  "filterEmails",
  "isLoading",
  "generateResponse",
  "detectEntitiesAndIntents",
  "content",
]);
const emit = defineEmits();

const selectedAll = ref(false);
const menuCompose = ref(null);

watch(
  () => currentPageEmailIds.value,
  () => {
    if (!currentPageEmailIds.value.length) {
      selectedAll.value = false;
      return;
    }

    const allSelected = currentPageEmailIds.value.some((id) =>
      selectedStoredMails.value.includes(id)
    );

    selectedAll.value = allSelected;
  },
  { immediate: true }
);

watch(
  () => selectedStoredMails.value.length,
  () => {
    const storedCondition = currentPageEmailIds.value.some((id) =>
      selectedStoredMails.value.includes(id)
    );

    if (selectedStoredMails.value.length !== 0 && storedCondition) {
      selectedAll.value = true;
    }

    if (storedCondition === false) {
      selectedAll.value = false;
    }
  },
  { immediate: true }
);
</script>
<template>
  <div class="mail-content">
    <div class="mail-sidebar">
      <MailSidebar
        :calculate-indexes="calculateIndexes"
        :mails="mails"
        :start-index="startIndex"
        :end-index="endIndex"
        :next-page="nextPage"
        :previous-page="previousPage"
        :ready="ready"
        :current-total-messages="currentTotalMessages"
        :inbox-message="inboxMessage"
      />
    </div>
    <div class="content-box">
      <EmptyMailContent :is-loading="isLoading" v-if="!content" />
      <MailContent
        v-if="content"
        :isLoading="isLoading"
        :generate-response="generateResponse"
        :detect-entities-and-intents="detectEntitiesAndIntents"
        :content="content"
        @openComposeAction="menuCompose.addSignature()"
        :insert-content="menuCompose.setContent"
      />
    </div>
    <MenuCompose ref="menuCompose" />
  </div>
</template>
<style scoped>
.mail-content {
  display: flex;
  width: 100%;
  height: 100%;
}

/* Sidebar dos e-mails */
.mail-sidebar {
  width: 390px;
  min-width: 390px;
  max-width: 390px;
  background-color: #fff;
  transition: all 0.3s;
  scrollbar-width: thin;
  scrollbar-color: #f5f6fa #dde1ed;
  overflow-y: auto;
  z-index: 5;
}

@media (prefers-reduced-motion: reduce) {
  .mail-sidebar {
    transition: none;
  }
}

@media (min-width: 768px) {
  .mail-sidebar {
    left: 240px;
    width: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .mail-sidebar {
    width: 390px;
    right: auto;
  }
}

/* Conteúdo do e-mail selecionado */
.content-box {
  width: calc(100% - 390px);
  height: 100%;
  overflow: hidden;
  position: relative;
}
</style>
