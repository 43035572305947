import axios from "axios";
import { base64UrlDecode } from "../../helpers/helpers";

export class FetchAPI {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.headers = { Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13" };
    this.defaultOptions = { headers: this.headers };
  }

  async sendRequest(endpoint, method = "GET", data = null) {
    const requestOptions = {
      ...this.defaultOptions,
      method,
      url: this.baseUrl + endpoint,
    };
    if (data) requestOptions.data = data;

    try {
      const response = await axios(requestOptions);
      return response.data;
    } catch (error) {
      console.error(`Error sending ${method} request to ${endpoint}:`, error);
      throw error;
    }
  }

  async getAllEmailsPaginated(
    inboxBusinessId = null,
    page = 1,
    size = 15,
    openedInbox = null,
    intentionLabel = null
  ) {
    let endpoint;

    if (openedInbox)
      endpoint = `/inbox-emails/v2?inbox_business_id=${inboxBusinessId}&inbox_origin=${openedInbox}&label_filters=SEM_FILTRO_APLICADO&page=${page}&size=${size}`;

    if (intentionLabel)
      endpoint = `/inbox-emails/v2?inbox_business_id=${inboxBusinessId}&intention_label=${intentionLabel}&page=${page}&size=${size}`;

    return await this.sendRequest(endpoint);
  }

  async getAllFilteredEmailsPaginated(
    data,
    inboxBusinessId,
    page = 1,
    size = 15
  ) {
    const endpoint = `/inbox-emails/search?inbox_business_client_id=${inboxBusinessId}&page=${page}&size=${size}`;

    return await this.sendRequest(endpoint, "POST", data);
  }

  async getAllInboxBusinessClients() {
    const endpoint = "/inbox-business-clients";

    return await this.sendRequest(endpoint);
  }

  async getInboxBusinessClientById(id) {
    const endpoint = `/inbox-business-clients/${id}`;

    return await this.sendRequest(endpoint);
  }

  async createInboxBusinessClient(name, user, password) {
    const endpoint = "/inbox-business-clients";
    const data = {
      name,
      details: { user, password },
      business_client_id: null,
      oportunity_id: null,
    };

    return await this.sendRequest(endpoint, "POST", data);
  }

  async addInboxBusinessClientAndIMAP(name, user, password) {
    const endpoint = "/email-reader";
    const data = {
      name,
      details: { user, password },
      business_client_id: null,
      oportunity_id: null,
    };

    return await this.sendRequest(endpoint, "POST", data);
  }

  async getMessage(inboxID, messageID) {
    const endpoint = `/inbox-emails/message-id/${inboxID}?message_id=${base64UrlDecode(
      messageID
    )}`;

    const data = await this.sendRequest(endpoint, "GET", null);

    return data.data.message;
  }

  async getEmailMetrics(id, startDate, endDate) {
    let endpoint;

    if (id) {
      endpoint = `/oportunities/metrics/summary?business_client_id=${id}&startDate=${startDate}&endDate=${endDate}`;
    }

    if (!id) {
      endpoint = `/oportunities/metrics/summary?startDate=${startDate}&endDate=${endDate}`;
    }

    console.log(endpoint);

    const response = await this.sendRequest(endpoint, "GET", null);

    return response.data;
  }

  async getMailboxMetadata(id) {
    const endpoint = `/inbox-business-clients/${id}`;

    const response = await this.sendRequest(endpoint, "GET", null);

    return response.data.meta_info;
  }

  async getMessageById(emailID) {
    const endpoint = `/inbox-emails/${emailID}`;

    const response = await this.sendRequest(endpoint, "GET", null);

    const mailData = response.data[0];

    return mailData;
  }
}
