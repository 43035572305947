<script setup>
//*Importações do Vue*
import { defineProps, inject, ref } from "vue";
const {
  inbox,
  mails,
  intentText,
  openWitAi,
  intentsWitAi,
  messageContent,
  isOpenHandleCompose,
  roleCommand,
  getMailboxMetadata,
  archiveMessage,
  openOpportunity,
  mailContent,
} = inject("inbox");

//*Bibliotecas*
import { PopUpNotify } from "../providers/popup-notify.js";
const languageModelGateway = inject("languageModelGateway");

//*Importações de Assets*
import witAI from "../assets/img/wit-ai.png";
import gptAI from "../assets/img/gpt-ai.png";

//*Helpers*
import { switchTermsToPTBR } from "../helpers/helpers.js";

//*Props *
const { content } = defineProps(["content"]);

const shakeElement = (element) => {
  element.classList.add("shake");
  setTimeout(() => {
    element.classList.remove("shake");
  }, 500);
};

const detectIntentions = async (content) => {
  try {
    const contentsText = content.content;
    messageContent.value = content;

    openWitAi.value = true;

    const analyzedIntents = await languageModelGateway.detectEntitiesAndIntents(
      contentsText
    );

    if (
      analyzedIntents &&
      analyzedIntents.intents &&
      analyzedIntents.intents.length === 0
    ) {
      const element = document.querySelector(".wit-ai-logo").parentElement;
      shakeElement(element);
      intentsWitAi.value = "Não-classificado";
    } else {
      intentsWitAi.value = analyzedIntents.intents;
      intentText.value = analyzedIntents.intents[0].name;
    }
  } catch (error) {
    console.error("Erro ao detectar intenções ou atualizar o e-mail:", error);
  }
};

const formulateReply = async (intents, content) => {
  isOpenHandleCompose.value = true;

  mailContent.value = content;

  try {
    const metaInfo = await getMailboxMetadata(inbox.value.id);

    roleCommand.value = `Você é o/a ${
      inbox.value.name
    }, SDR do time de vendas da empresa '${
      metaInfo && metaInfo.company_name && metaInfo.company_name.value
        ? metaInfo.company_name.value
        : "{{nome da empresa}}"
    }', especializado/a em ${
      metaInfo &&
      metaInfo.product_description &&
      metaInfo.product_description.value
        ? metaInfo.product_description.value
        : "{{descrição do segmento e produto}}"
    }. O usuário irá te enviar uma mensagem, e você sabe que a intenção do usuário é '${switchTermsToPTBR(
      intents
    )}'. Mantenha uma postura {{postura desejada}} ao responder.

Com base na intenção do cliente, siga rigorosamente as diretrizes abaixo para elaborar a melhor resposta:
{{diretrizes da intenção}}

Não forneça informações além dessas orientações. Foque exclusivamente nas diretrizes acima e responda apenas com o texto da resposta.`;
  } catch (error) {
    console.error(error);
  }
};

const archive = async (content) => {
  const mailbox = content.inboxOrigin;
  const uid = content.uid;

  const index = mails.value.findIndex((mail) => mail.id === content.id);
  const mailToRestore = index !== -1 ? mails.value[index] : null;

  if (index !== -1) mails.value.splice(index, 1);

  try {
    const data = await archiveMessage(mailbox, [uid]);

    if (data) {
      PopUpNotify.displaySuccess({
        message: "E-mail foi arquivado!",
        position: "top-center",
      });
    }
  } catch (error) {
    console.error(error);
    PopUpNotify.displayError({
      message: "Erro ao arquivar o e-mail. Ação desfeita!",
      position: "top-center",
    });

    if (mailToRestore && index !== -1) {
      mails.value.splice(index, 0, mailToRestore);
    }
  }
};

const options = ref([
  {
    label: "URL compartilhável",
    key: "GENERATE-URL",
  },
  {
    label: "Copiar ID",
    key: "COPY-ID",
  },
]);

const handleSelect = async (key, content) => {
  if (key === "COPY-ID") {
    const textToCopy = content.id;

    await navigator.clipboard.writeText(textToCopy);

    PopUpNotify.displaySuccess({
      message: "ID do e-mail copiado para área de transferência!",
      position: "top-center",
    });
  }

  if (key === "GENERATE-URL") {
    const urlToCopy = window.location.href;

    await navigator.clipboard.writeText(urlToCopy);

    PopUpNotify.displaySuccess({
      message: "Link para mensagem copiado para área de transferência!",
      position: "top-center",
    });
  }
};
</script>
<template>
  <div v-if="content" class="mailcontent-subject">
    <nav class="nav-wrapper">
      <div class="left-nav">
        <button class="button" @click="openOpportunity = !openOpportunity">
          Gerar oportunidade
        </button>
      </div>
      <div class="right-nav">
        <a
          href="#"
          class="nav-link"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
        >
        </a>
        <a
          v-if="
            content.inboxOrigin && !content.inboxOrigin.includes('Arquivados')
          "
          href="#"
          class="nav-link"
          data-bs-toggle="tooltip"
          title="Arquivar"
          data-bs-placement="left"
          @click="archive(content)"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            class="iconify iconify--solar mnl__icon__root MuiBox-root css-cnvj7y"
            width="23px"
            height="23px"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M20.535 3.464C19.07 2 16.713 2 11.999 2C7.285 2 4.93 2 3.464 3.464c-.758.758-1.123 1.754-1.3 3.192a6.5 6.5 0 0 1 1.884-1.448c.782-.398 1.619-.56 2.545-.635C7.488 4.5 8.59 4.5 9.936 4.5h4.126c1.347 0 2.448 0 3.343.073c.927.076 1.764.237 2.545.635a6.499 6.499 0 0 1 1.884 1.448c-.176-1.438-.542-2.434-1.3-3.192"
            ></path>
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M2 14c0-2.8 0-4.2.545-5.27A5 5 0 0 1 4.73 6.545C5.8 6 7.2 6 10 6h4c2.8 0 4.2 0 5.27.545a5 5 0 0 1 2.185 2.185C22 9.8 22 11.2 22 14c0 2.8 0 4.2-.545 5.27a5 5 0 0 1-2.185 2.185C18.2 22 16.8 22 14 22h-4c-2.8 0-4.2 0-5.27-.545a5 5 0 0 1-2.185-2.185C2 18.2 2 16.8 2 14m10.53 3.53a.75.75 0 0 1-1.06 0l-2.5-2.5a.75.75 0 1 1 1.06-1.06l1.22 1.22V11a.75.75 0 0 1 1.5 0v4.19l1.22-1.22a.75.75 0 1 1 1.06 1.06z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a>
        <a
          v-if="content.intentionText"
          @click="formulateReply(content.intentionText, content)"
          href="#"
          class="nav-link"
        >
          <img class="gpt-ai-logo" :src="gptAI" alt="gpt-ai" />
        </a>
        <a @click="detectIntentions(content)" href="#" class="nav-link">
          <img class="wit-ai-logo" :src="witAI" alt="wit-ai" />
        </a>
        <n-dropdown
          trigger="click"
          :options="options"
          @select="(key) => handleSelect(key, content)"
        >
          <a
            href="#"
            class="nav-link"
            data-bs-toggle="tooltip"
            title="More"
            data-bs-placement="left"
            ><i class="ri-more-2-fill"></i
          ></a>
        </n-dropdown>
      </div>
    </nav>
  </div>
</template>
<style scoped>
.mailcontent-show .mailbox {
  transform: translateX(-100%);
}

.mailcontent-show .mailcontent {
  left: 0;
}

@media (min-width: 768px) {
  .mailcontent-show .mailcontent {
    left: 230px;
  }
}

@media (min-width: 768px) {
  .mailcontent-show.sidebar-hide .mailcontent {
    left: 0;
  }
}

.header-content {
  position: absolute;
  top: 0px;
  background: #f4f6fa;
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid #333;
  border-color: rgba(204, 207, 217, 0.75);
}

.no-mailcontent-text {
  text-align: center;
  font-weight: 400;
  color: #a1a8bb;
}

.no-mailcontent-text span {
  font-size: 14px;
  margin-top: 5px;
}

.mailcontent {
  width: 100%;
  height: 100%;
  background-color: #f5f6fa;
  transition: all 0.3s;
  overflow: auto;
}

@media (prefers-reduced-motion: reduce) {
  .mailcontent {
    transition: none;
  }
}

@media (min-width: 768px) {
  .mailcontent {
    width: calc(100vw - 230px);
  }
}

@media (min-width: 1200px) {
  .mailcontent {
    width: auto;
    display: block;
    left: 620px;
    right: 0;
  }
}

.mailcontent-item {
  position: relative;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .mailcontent-item {
    padding: 0 25px;
  }
}

@media (max-width: 767.98px) {
  .mailcontent-item:first-child .mailcontent-header {
    padding-top: 0;
  }
}

.mailcontent-subject {
  padding: 10px;
}

@media (min-width: 768px) {
  .mailcontent-subject {
    padding: 20px;
  }
}

@media (min-width: 1200px) {
  .mailcontent-subject {
    display: flex;
    flex-direction: row-reverse;
    padding: 25px;
  }
}

.mailcontent-subject h4,
.mailcontent-subject .h4 {
  flex: 1;
  color: #212329;
  text-indent: -1px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 1.4;
  padding-left: 5px;
}

@media (min-width: 1200px) {
  .mailcontent-subject h4,
  .mailcontent-subject .h4 {
    margin-top: 0;
    margin-right: 25px;
    padding-left: 0;
  }
}

.mailcontent-subject .nav {
  flex-shrink: 0;
  margin-top: 2.5px;
  margin-left: auto;
}

.mailcontent-subject .nav-link:first-child {
  margin-right: auto;
}

@media (min-width: 1200px) {
  .mailcontent-subject .nav-link:first-child {
    display: none;
  }
}

.mailcontent-subject .nav-link i {
  font-size: 20px;
}

.mailcontent-header {
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.mail-avatar {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.mail-avatar img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.mailcontent-header .mail-avatar {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cccccc;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .mailcontent-header .mail-avatar {
    width: 38px;
    height: 38px;
  }
}

.mailcontent-header .nav {
  padding-left: 5px;
  margin-right: -5px;
}

@media (min-width: 768px) {
  .mailcontent-header .nav {
    padding-left: 10px;
    margin-right: 0;
  }
}

.mailcontent-header .nav-link i {
  font-size: 18px;
}

.mailcontent-subject .nav,
.mailcontent-header .nav {
  position: relative;
}

.mailcontent-subject .nav-link,
.mailcontent-header .nav-link {
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #6b748f;
}

@media (min-width: 768px) {
  .mailcontent-subject .nav-link + .nav-link,
  .mailcontent-header .nav-link + .nav-link {
    margin-left: 2px;
  }
}

.mailcontent-subject .nav-link:hover,
.mailcontent-header .nav-link:hover {
  background-color: rgba(221, 225, 237, 0.6);
}

.mailcontent-subject .nav-link:last-child,
.mailcontent-header .nav-link:last-child {
  width: 16px;
}

.mailcontent-sender {
  flex: 1;
  padding-left: 8px;
}

@media (min-width: 768px) {
  .mailcontent-sender {
    padding-left: 8px;
  }
}

.mailcontent-sender h6,
.mailcontent-sender .h6 {
  font-size: 14px;
  color: #212329;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .mailcontent-sender h6,
  .mailcontent-sender .h6 {
    margin-bottom: 2px;
  }
}

.mailcontent-sender h6 span,
.mailcontent-sender .h6 span {
  color: #a1a8bb;
  font-weight: 400;
  font-size: 13px;
  display: none;
}

@media (min-width: 768px) {
  .mailcontent-sender h6 span,
  .mailcontent-sender .h6 span {
    display: inline-block;
  }
}

.mailcontent-sender p {
  margin-bottom: 0;
  font-size: 12px;
}

@media (min-width: 768px) {
  .mailcontent-sender p {
    font-size: 13px;
  }
}

.mailcontent-time {
  font-size: 11px;
  font-weight: 400;
  color: #a1a8bb;
  display: flex;
  padding-left: 15px;
  justify-content: space-around;
}

@media (min-width: 768px) {
  .mailcontent-time {
    font-size: 12px;
  }
}

.mailcontent-time span {
  display: none;
}

@media (min-width: 768px) {
  .mailcontent-time span {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .mailcontent-body {
    margin: 10px 0;
    padding: 20px 25px 25px;
  }
}

.mailcontent-body hr {
  background-color: #a1a8bb;
  margin-top: 25px;
  margin-bottom: 5px;
}

.mailcontent-label {
  display: block;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #a1a8bb;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.attachment-group {
  position: relative;
}

@media (min-width: 768px) {
  .attachment-group {
    display: flex;
  }
}

.attachment-item {
  background-color: rgba(240, 242, 247, 0.6);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .attachment-item {
    min-width: 240px;
  }
}

.attachment-item + .attachment-item {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .attachment-item + .attachment-item {
    margin-top: 0;
    margin-left: 10px;
  }
}

.attachment-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: inherit;
  box-shadow: 0 1px 1px rgba(221, 225, 237, 0.25),
    0 2px 2px rgba(221, 225, 237, 0.2), 0 4px 4px rgba(221, 225, 237, 0.15),
    0 8px 8px rgba(221, 225, 237, 0.1), 0 16px 16px rgba(221, 225, 237, 0.05);
}

@media (min-width: 992px) {
  .attachment-icon {
    width: 50px;
    height: 50px;
  }
}

.attachment-icon i {
  font-size: 28px;
  line-height: 1;
}

.attachment-body {
  flex: 1;
  padding-left: 10px;
}

.attachment-body h6,
.attachment-body .h6 {
  margin-bottom: 2px;
  font-size: 14px;
  color: #212329;
}

.attachment-body a {
  color: inherit;
  text-decoration: none;
}

.attachment-body a:hover,
.attachment-body a:focus {
  color: #dc0f37;
}

.attachment-body span {
  display: block;
  font-size: 12px;
  color: #a1a8bb;
}

.mailcontent-footer {
  padding: 15px;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .mailcontent-footer {
    padding: 25px;
  }
}

.mailcontent-footer .btn {
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
}

.mailcontent-footer .btn + .btn {
  margin-left: 10px;
}

.card-mail {
  border-color: #7695f5;
  background-color: rgba(118, 149, 245, 0.08);
  border-width: 1.5px;
  border-radius: 0;
}

.card-mail .card-body {
  padding: 20px 25px;
  font-size: 14px;
}

.card-mail .card-title {
  color: #212329;
  margin-bottom: 12px;
}

.card-mail ul {
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 15px;
}

.mailcontent-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mailcontent-placeholder i {
  font-size: 128px;
  line-height: 1;
  margin-bottom: 10px;
  color: #a1a8bb;
  opacity: 0.6;
}

.mailcontent-placeholder h5,
.mailcontent-placeholder .h5 {
  font-weight: 400;
  color: #a1a8bb;
  margin-bottom: 0;
}

.wit-ai-logo {
  width: 23px;
  height: 23px;
  margin-bottom: 7px;
}

.gpt-ai-logo {
  width: 22px;
  height: 20px;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
}

.shake {
  animation: shake 0.5s;
  transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mailcontent-subtitle h4 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-weight: 400;
  flex-shrink: 0;
  padding: 16px;
  font-size: 18px;
}

.reply-wrapper {
  display: flex;
  gap: 5px;
}

.nav-wrapper {
  height: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.left-nav {
  display: flex;
  justify-content: flex-start;
}

.right-nav {
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 170px;
  cursor: pointer;
  height: 40px;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 19px;
  background-color: #dc0f37;
  border: none;
}
.button:active {
  box-shadow: 0 4px #ea6f87;
  transform: translateY(4px);
}
</style>
