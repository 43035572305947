<script setup>
import { ref, onMounted } from "vue";
import { toast } from "vue3-toastify";
import axios from "axios";
import config from "../../../../config/environment.js";

const organizationNames = ref([]);

onMounted(async () => {

  const response = await axios.get(`${config.GROWTH_DB}/api/v2/organizations`, {
          headers: {
            Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13"
          }
        });

        response.data.data.data.forEach(item => {
          if (item.name) {
            organizationNames.value.push(item.name);
          }
        });

});

const years = [2024, 2025, 2026];

const months = [
  { name: "Janeiro", value: "janeiro", mm: "01" },
  { name: "Fevereiro", value: "fevereiro", mm: "02" },
  { name: "Março", value: "marco", mm: "03" },
  { name: "Abril", value: "abril", mm: "04" },
  { name: "Maio", value: "maio", mm: "05" },
  { name: "Junho", value: "junho", mm: "06" },
  { name: "Julho", value: "julho", mm: "07" },
  { name: "Agosto", value: "agosto", mm: "08" },
  { name: "Setembro", value: "setembro", mm: "09" },
  { name: "Outubro", value: "outubro", mm: "10" },
  { name: "Novembro", value: "novembro", mm: "11" },
  { name: "Dezembro", value: "dezembro", mm: "12" },
];

const translateKeyTerms = (key) => {
  const term = {
    click: "Cliques",
    delivery: "Entregas",
    open: "Aberturas",
    reject: "Rejeições",
    deliveryDelay: "Atrasos na entrega",
    complaint: "Reclamações",
    renderingFailure: "Falhas de renderização",
    subscription: "Inscrições",
    bounce: "Devoluções",
    opportunity: "Oportunidades",
  };

  return term[key] || key;
};

const selectedMonth = ref(months[0].value);
const selectedYear = ref(years[0]);
const selectedOrganization = ref("");

const kpis = ref({
    click: 0,
    delivery: 0,
    open: 0,
    reject: 0,
    deliveryDelay: 0,
    complaint: 0,
    renderingFailure: 0,
    subscription: 0,
    bounce: 0,
    opportunity: 0,
});

const fetchMonthlyData = async () => {
  const monthNumber = (
    "0" +
    (months.findIndex((month) => month.value === selectedMonth.value) + 1)
  ).slice(-2);

  const startDate = `${selectedYear.value}-${monthNumber}-01`;
  const endDate = new Date(selectedYear.value, monthNumber, 0)
    .toISOString()
    .split("T")[0];

  let id;

  try {
    id = toast.loading("Buscando dados...", {
      position: "top-right",
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
    });

    const response = await axios.post(
  `${config.GROWTH_DB}/api/v2/events/analytical?year=${selectedYear.value}&startDate=${startDate}&endDate=${endDate}`,
  { organizationName: selectedOrganization.value },
  {
    headers: {
      Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13"
    }
  }
);

    console.log(response.data.data);

    Object.keys(kpis.value).forEach(key => {
      kpis.value[key] = response.data.data[key] || 0;
    });

    toast.update(id, {
      render: "Métricas buscadas com sucesso!",
      type: "success",
      isLoading: false,
      autoClose: 5000,
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "green",
      },
    });
  } catch (error) {
    console.error("Error fetching data:", error);
    toast.update(id, {
      render:
        "Erro ao buscar métricas! Pode ser que não haja registros para o mês/ano selecionado",
      type: "error",
      isLoading: false,
      autoClose: 5000,
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
    });
  }
};
</script>
<template>
  <div class="dashboard-container">
    <h1>Análise de eventos</h1>
    <div class="month-picker">
      <label for="organization">Organização:</label>
      <select id="organization" v-model="selectedOrganization">
        <option v-for="organizationName in organizationNames" :value="organizationName" :key="organizationName">
          {{ organizationName }}
        </option>
      </select>
      <label for="month">Selecione o mês:</label>
      <select id="month" v-model="selectedMonth">
        <option v-for="month in months" :value="month.value" :key="month.value">
          {{ month.name }}
        </option>
      </select>

      <label for="year">Selecione o Ano:</label>
      <select id="year" v-model="selectedYear">
        <option v-for="year in years" :value="year" :key="year">
          {{ year }}
        </option>
      </select>
      <button @click="fetchMonthlyData">Buscar informações</button>
    </div>
    <div class="kpi-container">
    <div class="kpi" v-for="(value, key) in kpis" :key="key">
      <h3>{{ translateKeyTerms(key) }}</h3>
      <p>{{ value }}</p>
    </div>
  </div>
  </div>
</template>
<style scoped>
.dashboard-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  height: 100vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.month-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

label {
  margin: 0 10px;
  color: #333;
}

select {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #ff4d4d;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #e60000;
}

.chart-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.kpi-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}

.kpi {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-left: 5px solid #dc3545;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px;
  flex: 1 1 200px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.kpi h3 {
  color: #ff4d4d;
  font-size: 12.6px;
  margin-top: 15px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
}

.kpi p {
  display: flex;
  justify-content: flex-start;
  font-size: 1.2em;
  color: #333;
  font-weight: 600;
}
</style>
