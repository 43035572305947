<script setup>
//* Importações do vue*
import {
  ref,
  defineProps,
  // defineEmits,
  // computed,
  onMounted,
  onBeforeUnmount,
  inject,
} from "vue";

import router from "../../../router";

const { currentPage, inbox, inboxUserStatus } = inject("inbox");

//* Importações de assets*
import emailProfileJpeg from "../assets/img/email-icon.jpeg";

const dropDown = ref(null);

const props = defineProps({
  options: { type: Array, default: [] },
  // modelValue: { type: Object, default: null },
});

// const emit = defineEmits(["update:modelValue"]);

// const selectedOption = ref(null);

const isDropDownVisible = ref(false);

// const mappedSelectedOption = computed(() => {
//   return selectedOption.value;
// });

const toggleOptionSelect = (option) => {
  currentPage.value = 1;
  // selectedOption.value = option;

  inbox.value = option;

  router.push({
    path: `/inbox/${inbox.value.id}/dashboard`,
  });

  // emit("update:modelValue", option);
  setTimeout(() => {
    isDropDownVisible.value = false;
  }, 300);
};

const closeDropDown = (element) => {
  if (!dropDown.value.contains(element.target)) {
    isDropDownVisible.value = false;
  }
};

onMounted(() => {
  window.addEventListener("click", closeDropDown);
});

onBeforeUnmount(() => {
  window.removeEventListener("click", closeDropDown);
});
</script>

<template>
  <div class="dropdown-wrapper" ref="dropDown">
    <div
      v-if="Object.keys(inbox).length === 0"
      class="dropdown-selected-option-loading"
    >
      <div class="profile-icon"></div>
      <div class="selected-text">
        <span class="selected-name"></span>
        <span class="selected-user"></span>
      </div>
    </div>

    <div
      v-else
      class="dropdown-selected-option"
      @click="isDropDownVisible = true"
    >
      <img
        :src="emailProfileJpeg"
        alt="company-icon"
        class="profile-icon"
        :class="{
          'border-green': inboxUserStatus,
          'border-grey': !inboxUserStatus,
        }"
      />
      <div class="selected-text">
        <span class="selected-name">{{ inbox.name }}</span>
        <span class="selected-user">{{ inbox.details?.user }}</span>
      </div>
    </div>
    <transition class="transition" name="slide-fade">
      <div class="options-wrapper" v-if="isDropDownVisible">
        <div
          class="option"
          v-for="(option, index) in props.options"
          :key="option.id"
          :value="option"
          @click="toggleOptionSelect(option, option.id, index)"
        >
          <img
            :src="emailProfileJpeg"
            alt="company-icon"
            class="profile-icon-option"
          />
          {{ option.details.user }}
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.dropdown-wrapper {
  cursor: pointer;
  max-width: 200px;
  margin: 0 auto;
  position: relative;
}

.dropdown-selected-option {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 4px;
  padding: 8px;
  width: 200px;
  background-color: #dc0f37;
}

.profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  flex-shrink: 0;
}

.border-green {
  outline: 3px solid #2bcf6e;
}

.border-grey {
  border: 2px solid grey;
}

.selected-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-width: 0;
}

.selected-name {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-user {
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.options-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #444;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}

.option {
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #dc0f37;
  z-index: 999;
  position: relative;
}
.option:last-of-type {
  border-bottom: none;
}

.option:hover {
  background: #b00e2d;
}

.profile-icon-option {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 6px;
  margin-right: 10px;
}

/* TRANSIÇÃO */

.transition {
  z-index: 999;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

/* LOADING DE ESQUELETO PARA .dropdown-selected-option */
.dropdown-selected-option-loading {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 4px;
  padding: 8px;
  width: 200px;
  background-color: #dc0f37;
}

.dropdown-selected-option-loading .profile-icon {
  background: #b00e2d;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.dropdown-selected-option-loading .selected-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-width: 0;
}

.dropdown-selected-option-loading .selected-text span {
  display: block;
  background: #b00e2d;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.dropdown-selected-option-loading .selected-name {
  height: 16px;
  width: 100px;
  margin-bottom: 4px;
}

.dropdown-selected-option-loading .selected-user {
  height: 12px;
  width: 150px;
}

.dropdown-selected-option-loading .profile-icon::before,
.dropdown-selected-option-loading .selected-text span::before {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #b00e2d 0%,
    rgba(255, 255, 255, 0.1) 20%,
    #b00e2d 40%,
    #b00e2d 100%
  );
  background-repeat: no-repeat;
  background-size: 450px 400px;
}
</style>
