<script setup>
import { defineProps, defineEmits, watch, ref, inject } from "vue";

// *Assets *
import circle from "../assets/img/circle.png";

//*Helpers *
import { switchTermsToPTBR, truncateContent } from "../helpers/helpers";

const getAddress = (from, fromAddress) => {
  return from || fromAddress;
};

const {
  selectedStoredMails,
  inbox,
  currentPageEmailIds,
  inboxMessage,
  readMessage,
} = inject("inbox");

const { email } = defineProps(["email"]);
const emit = defineEmits();

const selectMail = async (email) => {
  emit("mailSelected", email);
  if (!email.mark_seen) {
    email.mark_seen = true;
    inboxMessage.unReadInbox -= 1;
    const uid = email.uid;

    const boxPath = email.inboxOrigin;

    try {
      await readMessage(uid, boxPath);
    } catch (error) {
      console.error("Erro ao marcar o email como lido:", error);
    }
  }
};

const selectedAll = ref(false);

const toggleSelectEmail = (emailId) => {
  const index = selectedStoredMails.value.indexOf(emailId);
  if (index > -1) return selectedStoredMails.value.splice(index, 1);
  selectedStoredMails.value.push(emailId);
};

watch(
  () => currentPageEmailIds.value,
  () => {
    if (!currentPageEmailIds.value.length) {
      selectedAll.value = false;
      return;
    }

    const allSelected = currentPageEmailIds.value.some((id) =>
      selectedStoredMails.value.includes(id)
    );

    selectedAll.value = allSelected;
  },
  { immediate: true }
);

watch(
  () => selectedStoredMails.value.length,
  () => {
    const storedCondition = currentPageEmailIds.value.some((id) =>
      selectedStoredMails.value.includes(id)
    );

    if (selectedStoredMails.value.length !== 0 && storedCondition) {
      selectedAll.value = true;
    }

    if (storedCondition === false) {
      selectedAll.value = false;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="mail-item"
    :id="email.id"
    :key="email.id"
    @click="selectMail(email)"
    :class="email.mark_seen ? '' : 'unread'"
  >
    <div class="checkbox" @click.stop>
      <n-checkbox
        size="small"
        :checked="selectedStoredMails.includes(email.id)"
        @click="toggleSelectEmail(email.id)"
      />
    </div>
    <div class="mail-item-body">
      <div class="d-flex align-items-center">
        <span class="mail-sender">{{
          email.fromAddress === inbox.details.user
            ? `Para: ${getAddress(email.to, email.toAddress)}`
            : getAddress(email.from, email.fromAddress)
        }}</span>
        <span class="mail-time">{{ email.date }}</span>
        <span class="mail-star">
          <img
            v-show="!email.mark_seen"
            class="mail-new"
            :src="circle"
            alt="circle"
          />

          <i class="ri-star-line"></i>
          <i class="ri-star-fill"></i>
        </span>
      </div>
      <h6 class="mail-subject">{{ email.subject || "Sem assunto" }}</h6>
      <p class="mail-text">{{ truncateContent(email.content) }}</p>
      <div
        class="tag"
        :class="email.intentionText.toLowerCase()"
        v-if="email.intentionText && email.intentionText !== 'uncategorized'"
      >
        <span v-if="email.intentionText !== 'uncategorized'">
          {{ switchTermsToPTBR(email.intentionText) }}</span
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
.mailbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transition: all 0.3s;
  scrollbar-width: thin;
  scrollbar-color: #f5f6fa #dde1ed;
  overflow-y: auto;
  z-index: 5;
}

@media (prefers-reduced-motion: reduce) {
  .mailbox {
    transition: none;
  }
}

@media (min-width: 768px) {
  .mailbox {
    left: 240px;
    width: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .mailbox {
    width: 390px;
    right: auto;
  }
}

.loader-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mailbox-header {
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
}

@media (min-width: 768px) {
  .mailbox-header {
    padding: 20px 20px 10px;
  }
}

.mailbox-header .mail-avatar {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 10;
  display: block;
  text-decoration: none;
}

@media (min-width: 768px) {
  .mailbox-header .mail-avatar {
    top: 28px;
    right: 30px;
  }
}

.mailbox-header .mail-avatar span {
  background-color: #dc0f37;
  font-size: 14px;
}

.mailbox-menu {
  height: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  margin-right: 10px;
  text-decoration: none;
  text-indent: -2px;
  color: #343740;
}

@media (min-width: 768px) {
  .mailbox-menu {
    margin-left: 0;
    margin-right: 15px;
  }
}

.mailbox-menu:hover,
.mailbox-menu:focus {
  color: #dc0f37;
}

.mailbox-menu i {
  font-size: 24px;
  line-height: 1;
}

.mailbox-search {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #dde1ed;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(221, 225, 237, 0.25),
    0 2px 2px rgba(221, 225, 237, 0.2), 0 4px 4px rgba(221, 225, 237, 0.15),
    0 8px 8px rgba(221, 225, 237, 0.1), 0 16px 16px rgba(221, 225, 237, 0.05);
}

.mailbox-search i {
  font-size: 20px;
  line-height: 1;
  color: #6b748f;
  margin-top: -2px;
}

.mailbox-search .form-control {
  flex: 1;
  background-color: transparent;
  border-width: 0;
  font-size: 14px;
  padding-left: 8px;
  padding-top: 5px;
}

.mailbox-search .form-control:focus {
  box-shadow: none;
}

.mailbox-search .form-control::placeholder {
  color: #a1a8bb;
  text-shadow: none;
}

.mailbox-option {
  padding: 5px 10px 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
}

@media (min-width: 768px) {
  .mailbox-option {
    padding: 5px 20px 15px;
  }
}

.mailbox-option span {
  margin-right: 10px;
  color: #a1a8bb;
  font-size: 12px;
  letter-spacing: 0.2px;
}

.mailbox-option .pager {
  position: relative;
  width: 28px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #cccfd9;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #6b748f;
  text-decoration: none;
  box-shadow: 0 1px 1px rgba(233, 234, 240, 0.25),
    0 2px 2px rgba(233, 234, 240, 0.2), 0 4px 4px rgba(233, 234, 240, 0.15),
    0 8px 8px rgba(233, 234, 240, 0.1), 0 16px 16px rgba(233, 234, 240, 0.05);
  cursor: pointer;
  margin-left: 5px;
}

.mailbox-option .pager:hover,
.mailbox-option .pager:focus {
  background-color: #e9ecf3;
}

.mailbox-option .pager i {
  font-size: 22px;
  line-height: 1;
}

.mailbox-option .pager + .pager {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.mailbox-select {
  display: flex;
  margin-left: 10px;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(233, 234, 240, 0.25),
    0 2px 2px rgba(233, 234, 240, 0.2), 0 4px 4px rgba(233, 234, 240, 0.15),
    0 8px 8px rgba(233, 234, 240, 0.1), 0 16px 16px rgba(233, 234, 240, 0.05);
}

.mailbox-select .dropdown-check {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cccfd9;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  color: #6b748f;
  text-decoration: none;
}

.mailbox-select .dropdown-check:hover,
.mailbox-select .dropdown-check:focus {
  background-color: #f0f2f7;
}

.mailbox-select .dropdown-check i {
  font-size: 18px;
  line-height: 1;
  border-radius: 2px;
}

.mailbox-select .dropdown-check i:not(:first-child) {
  display: none;
}

.mailbox-select .dropdown-check.checkall i {
  color: #dc0f37;
}

.mailbox-select .dropdown-check.checkall i:first-child {
  display: none;
}

.mailbox-select .dropdown-check.checkall i:nth-child(2) {
  display: block;
}

.mailbox-select .dropdown-check.checkall i:last-child {
  display: none;
}

.mailbox-select .dropdown-check.check i {
  color: #dc0f37;
}

.mailbox-select .dropdown-check.check i:first-child,
.mailbox-select .dropdown-check.check i:nth-child(2) {
  display: none;
}

.mailbox-select .dropdown-check.check i:last-child {
  display: block;
}

.mailbox-select .dropdown-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 32px;
  text-decoration: none;
  color: #6b748f;
  font-size: 18px;
  border: 1px solid #cccfd9;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  margin-left: -1px;
}

.mailbox-select .dropdown-link:hover,
.mailbox-select .dropdown-link:focus {
  background-color: #f0f2f7;
}

.mailbox-select .dropdown-menu {
  margin-top: 2px !important;
  width: 180px;
  padding: 5px;
  border-color: rgba(33, 35, 41, 0.12);
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(204, 207, 217, 0.08),
    0 2px 2px rgba(204, 207, 217, 0.12), 0 4px 4px rgba(204, 207, 217, 0.16),
    0 8px 8px rgba(204, 207, 217, 0.2);
}

.mailbox-select .dropdown-item {
  font-size: 14px;
  color: #212329;
  padding: 6px 8px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.mailbox-select .dropdown-item + .dropdown-item {
  margin-top: 1px;
}

.mailbox-select .dropdown-item:hover {
  background-color: #f5f6fa;
}

.mailbox-select .dropdown-item i {
  font-size: 16px;
  line-height: 1.2px;
  margin-right: 7px;
  opacity: 0;
}

.mailbox-select .dropdown-item.active {
  background-color: #edf1fe;
  color: #dc0f37;
}

.mailbox-select .dropdown-item.active i {
  opacity: 1;
}

.mail-group {
  position: relative;
  padding: 0 10px;
  margin: 0;
  list-style: none;
  height: calc(100vh - 115px);
  overflow-y: auto;
}

@media (min-width: 768px) {
  .mail-group {
    padding: 0 20px;
    height: calc(100vh - 130px);
  }
}

.mail-item {
  position: relative;
  min-height: 100px;
  border-radius: 5px;
  padding: 10px 10px 12px;
  background-color: #f5f6fa;
  border: 1px solid transparent;
  display: flex;
  overflow: hidden;
}

.mail-item:last-of-type {
  margin-bottom: 10px;
}

.mail-item + .mail-item {
  margin-top: 2px;
}

.mail-item:hover,
.mail-item:focus {
  cursor: pointer;
  z-index: 6;
  border-color: rgba(118, 149, 245, 0.6);
  background-color: rgba(245, 246, 250, 0.6);
}

.mail-item.unread {
  background-color: #fff;
}

.mail-item.unread .mail-sender,
.mail-item.unread .mail-time {
  font-weight: 500;
  color: #212329;
}

.mail-item.unread .mail-subject {
  font-weight: 500;
  color: #212329;
}

@media (min-width: 768px) {
  .mail-item.active {
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.75);
    border-color: #7695f5;
    box-shadow: 0 1px 1px rgba(204, 207, 217, 0.25),
      0 2px 2px rgba(204, 207, 217, 0.2), 0 4px 4px rgba(204, 207, 217, 0.15),
      0 8px 8px rgba(204, 207, 217, 0.1), 0 16px 16px rgba(204, 207, 217, 0.05);
  }
}

.mail-item.selected {
  background-color: #e8edfd;
}

.mail-avatar {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.mail-avatar img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.mail-item-body {
  flex: 1;
  padding-left: 15px;
}

.mail-item-body > div:first-child {
  margin-bottom: 2px;
}

.mail-sender {
  font-size: 12px;
  margin-right: auto;
}

.mail-time {
  font-size: 11px;
  color: #a1a8bb;
  margin-right: 25px;
}

.mail-star:hover,
.mail-star:focus {
  color: #ffc107;
}

.mail-star i:last-child {
  display: none;
}

.mail-star.active {
  color: #ffc107;
}

.mail-star.active i:first-child {
  display: none;
}

.mail-star.active i:last-child {
  display: inline;
}

.mail-subject {
  font-size: 14px;
  font-weight: 400;
  color: #212329;
  margin-bottom: 5px;
}

.mail-subject span {
  font-weight: 300;
  color: #a1a8bb;
}

.mail-text {
  margin-bottom: 0;
  color: #6b748f;
  font-size: 13px;
}

.mail-star {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  top: 0.5px;
  line-height: 1;
}

.mail-new {
  height: 9px;
  width: 9px;
}

.ri-star-line,
.ri-star-fill {
  font-size: 13px;
}

.tag {
  color: white;
  padding: 3px;
  margin: 3px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-align: center;
  float: left;
}
.tag span {
  padding: 0px 5px;
  font-size: 12px;
}

.presentation {
  background-color: rgb(255 0 174 / 50%);
}

.referral {
  background-color: rgba(0, 38, 255, 0.5);
}

.mailing_exclusion {
  background-color: rgba(255, 0, 0, 0.5);
}

.prospecting {
  background-color: rgba(255, 145, 0, 0.5);
}

.scheduling {
  background-color: rgba(0, 255, 0, 0.5);
}

.no_interest {
  background-color: rgba(128, 128, 128, 1);
}

.selected-options {
  cursor: pointer;
  display: flex;
  margin-left: 6px;
}

.selected-amount {
  margin-left: 10px;
}

.empty-folder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 243px;
}

.empty-upper-text-msg {
  color: #919eab;
  font-weight: 600;
  line-height: 1.55556;
  text-align: center;
  margin: 8px 0px 0px;
  font-size: 1.0625rem;
}

.empty-lower-text-msg {
  margin: 8px 0px 0px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5;
  text-align: center;
  color: #919eab;
}
</style>
